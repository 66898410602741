import axios from "axios";

const NEWS_API_BASE_URL = "https://newsdata.io/api/1";

export async function getNewsInfo(country, category) {
  try {
    const response = await axios.get(`${NEWS_API_BASE_URL}/news`, {
      params: {
        country: country,
        category: category,
        apikey: "pub_313614c74998455a6840639eb4a117f840024",
      },
    });
    const newsData = response.data;
    return newsData;
  } catch (error) {
    console.error("Помилка запиту до API:", error);
    throw error;
  }
}
