import styled from "styled-components";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

export const StyledTableContainer = styled(TableContainer)`
  max-width: 650px;
  height: 100%;
  overflow-x: hidden;
`;

export const StyledTable = styled(Table)`
  max-width: 650px;

  @media only screen and (max-width: 767px) {
    max-width: 319px;
  }
`;

export const StyledTableCell = styled(TableCell)`
  && {
    font-size: 12px;
    letter-spacing: -0.8px;
    color: #333;
    padding: 8px;
    text-overflow: ellipsis;
    @media only screen and (max-width: 767px) {
      padding: 4px;
    }
  }
`;

export const StyledTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f3f3f3;
  }
`;

export const StyledTableHead = styled(TableHead)`
  && {
    background-color: #333;
    color: #fff;
  }
`;

export const StyledImage = styled.img`
  margin-left: auto;
  margin-right: auto;
  width: 50px;
  height: 50px;
  @media only screen and (max-width: 767px) {
    width: 35px;
    height: 35px;
  }
`;

export const StyledTableBody = styled(TableBody)`
  /* Додайте стилі для таблиці, які потрібні */
`;

export const StyledPaper = styled(Paper)`
  max-width: 650px;
  @media only screen and (max-width: 767px) {
    max-width: 319px;
  }
`;
