import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledHeaderLink = styled(Link)`
  margin: 0;
  @media only screen and (max-width: 1023px) {
    display: none;
  }
`;

export const HeaderWrapper = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px 10px;
  border-bottom: 2px solid #18181a;
  box-shadow: 0 0 8px 0 rgb(42, 42, 44);
  background-color: #3d4721;
`;

export const HeaderTitle = styled.h1`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;
  @media only screen and (max-width: 1024px) {
    font-size: 18px;
    font-weight: 500;
  }
`;

export const HeaderImage = styled.img`
  margin: 0;
  @media only screen and (max-width: 1023px) {
    display: none;
  }
`;

export const HeaderButton = styled.button`
  display: none;
  color: #fff;
  border: none;
  padding: 4px;
  
  background-color: transparent;
  @media only screen and (max-width: 767px) {
    display: block;
  }
`;

export const HeaderAthuButton = styled.button`
display: flex;
justify-content: center;
text-align: center;
text-decoration: none;
background-color: transparent;
color: #a0ac82;
border: #a0ac82 solid;
border-radius: 20px;
padding: 8px;
transition-property: background-color;
transition-duration: 250ms;
transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
&.active {
  border: #a0ac82 solid;
  color: #a0ac82;
}
&:hover,
&:focus {
  border: #a0ac82 solid;
  color: #a0ac82;
}`
