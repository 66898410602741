import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const NavigationList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 8px;
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

export const NavigationLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  color: #fff;
  border: #fff solid;
  border-radius: 20px;
  padding: 8px;
  transition-property: background-color;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  &.active {
    border: #a0ac82 solid;
    color: #a0ac82;
  }
  &:hover,
  &:focus {
    border: #a0ac82 solid;
    color: #a0ac82;
  }
  @media only screen and (max-width: 1100px) {
    font-size: 12px;
    letter-spacing: -0.8px;
    padding: 5px;
  }
`;
