import React from "react";
import { useTranslation } from "react-i18next";
import { NavigationList, NavigationLink } from "./Navigation.styled";

function Navigation() {
  const { t } = useTranslation();
  
  return (
    <nav>
      <NavigationList>
        <li>
          <NavigationLink to="/">{t('navigation.homeLink')}</NavigationLink>
        </li>
        <li>
          <NavigationLink to="/questions">{t('navigation.questionsLink')}</NavigationLink>
        </li>
        <li>
          <NavigationLink to="/advantages">{t('navigation.advantagesLink')}</NavigationLink>
        </li>
        <li>
          <NavigationLink to="/contract">{t('navigation.contractLink')}</NavigationLink>
        </li>
        <li>
          <NavigationLink to="/questionnaire">{t('navigation.questionnaireLink')}</NavigationLink>
        </li>
        <li>
          <NavigationLink to="/contacts">{t('navigation.contactsLink')}</NavigationLink>
        </li>
        <li>
          <NavigationLink to="/news">{t('navigation.newsLink')}</NavigationLink>
        </li>
      </NavigationList>
     
    
    </nav>
  );
}

export default Navigation;

