import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getLatestStatisticsInfo } from "../../services/API_STARISTICS_DATA";
import { getStatisticsInfo } from "../../services/API_STATISTICS";
import { getNewsInfo } from "../../services/NEWS_API";
import StatisticsTable from "../../components/StatisticsTable/StatisticsTable";
import {
  HomePageTitle,
  HomePageNewsContainer,
  HomePageNewsList,
  HomePageNewsItem,
  HomePageNewsImg,
  HomePageNewsTitles,
  HomePageNewsLink,
  HomePageContainer,
} from "./HomePage.styled";

import defaultImage from "./HomePageImg/slava-UA.jpg";

function HomePage() {
  const { t } = useTranslation();

  const [data, setData] = useState({});
  const [statisticData, setStatisticData] = useState({});
  const [newsData, setNewsData] = useState(null);

  useEffect(() => {
    const fetchNewsData = async () => {
      try {
        const result = await getNewsInfo("ua");
        setNewsData(result);
      } catch (error) {
        console.error("Помилка отримання даних:", error);
      }
    };

    fetchNewsData();
  }, []);

  useEffect(() => {
    const fetchStatisticsData = async () => {
      try {
        const result = await getStatisticsInfo("ua", "top");
        setData(result);
      } catch (error) {
        console.error("Помилка отримання даних:", error);
      }
    };

    fetchStatisticsData();
  }, []);

  useEffect(() => {
    const fetchLatestStatisticsData = async () => {
      try {
        const result = await getLatestStatisticsInfo();
        setStatisticData(result);
      } catch (error) {
        console.error("Помилка отримання даних:", error);
      }
    };

    fetchLatestStatisticsData();
  }, []);

  return (
    <div>
      <HomePageTitle>
      {t("homePageTitel.title")}
      </HomePageTitle>
      <HomePageContainer>
        <StatisticsTable data={data} statisticData={statisticData} />
        
          <HomePageNewsContainer>
            <HomePageNewsList>
              {newsData &&
                newsData.results.map((newsItem) => (
                  <HomePageNewsItem key={newsItem.article_id}>
                    <HomePageNewsLink
                      href={newsItem.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <HomePageNewsImg
                        src={newsItem.image_url || defaultImage}
                        alt={newsItem.title}
                      />
                      <HomePageNewsTitles>{newsItem.title}</HomePageNewsTitles>
                    </HomePageNewsLink>
                  </HomePageNewsItem>
                ))}
            </HomePageNewsList>
          </HomePageNewsContainer>
       
      </HomePageContainer>
    </div>
  );
}

export default HomePage;
