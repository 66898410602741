import axios from "axios";

const BASE_URL = "https://russianwarship.rip/api/v2";

export async function getLatestStatisticsInfo() {
  try {
    const response = await axios.get(`${BASE_URL}/statistics/latest`);
    const statisticData = response.data.data;
    return statisticData;
    
  } catch (error) {
    console.error("Помилка запиту до API:", error);
    throw error;
  }
}