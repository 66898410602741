import React from "react";
import {
  StyledFooter,
  StyledContactsList,
  StyledContactsLinks,
} from "./Footer.styled";

const Footer = () => {
  return (
    <StyledFooter>
      <address>
        <StyledContactsList>
          <li>
            <StyledContactsLinks href="tel:+380962479100">
              +38(096)-247-91-00
            </StyledContactsLinks>
          </li>
          <li>
            <StyledContactsLinks href="viber://add?number=+380979849516">
              Viber
            </StyledContactsLinks>
          </li>
          <li>
            <StyledContactsLinks href="tg://resolve?domain=Bekos78">
              Telegram
            </StyledContactsLinks>
          </li>
          <li>
            <StyledContactsLinks href="mailto:`hrcktasp@ukr.net`">
              E-mail
            </StyledContactsLinks>
          </li>
        </StyledContactsList>
      </address>
    </StyledFooter>
  );
};

export default Footer;
