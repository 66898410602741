import React from "react";
import {
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
  StyledImage,
  StyledPaper,
} from "./StatisticsTable.styled";

function StatisticsTable({ data, statisticData }) {
  return (
    <StyledTableContainer component={StyledPaper}>
      <StyledTable>
        <StyledTableHead>
          <StyledTableRow>
            <StyledTableCell>Втрати ворога</StyledTableCell>
            <StyledTableCell>Слава Україні!</StyledTableCell>
          </StyledTableRow>
        </StyledTableHead>
        <StyledTableBody>
          {Object.keys(data).map((key) => (
            <StyledTableRow key={key}>
              <StyledTableCell>
                {data[key].title}: {statisticData.stats[key]} +{" "}
                {statisticData.increase[key]}
              </StyledTableCell>
              <StyledTableCell>
                <StyledImage src={data[key].icon} alt={data[key].title} />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </StyledTableBody>
      </StyledTable>
    </StyledTableContainer>
  );
}

export default StatisticsTable;
