import styled from "styled-components";

export const LanguageContainer = styled.div`
  margin-left: 8px;
`;

export const LanguageButton = styled.button`
 margin-top: 2px;
 text-decoration: none;
  color: #a0ac82;
  border: #a0ac82 solid;
  border-radius: 20px;
  padding: 2px;
  background-color: transparent;
  &:hover,
  &:focus {
    border: #fff solid;
    color: #fff;
`;
