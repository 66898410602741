import React, { useState, useEffect, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Layout from "../components/Layout/Layout";
import { LoadingMessage } from "../Pages/HomePage/HomePage.styled";
import HomePage from "../Pages/HomePage/HomePage";
import Auth from "./Authorization/Auth";
import { ModalProvider } from "react-modal-hook";
import { onAuthStateChanged, getAuth } from "firebase/auth";

// Продовжуйте імпортувати компоненти з лінивою загрузкою
const Questions = lazy(() => import("../Pages/QuestionsPage/Questions"));
const Advantages = lazy(() => import("../Pages/AdvantagesPage/Advantages"));
const Contract = lazy(() => import("../Pages/ContractPage/Contract"));
const Questionnaire = lazy(() =>
  import("../Pages/QuestionnairePage/Questionnaire")
);
const Contacts = lazy(() => import("../Pages/ContactsPage/Contacts"));
const News = lazy(() => import("../Pages/NewsPage/News"));

function App() {
  const [isAdmin, setAdmin] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const isAdmin = user.uid === "TlZVh94TP8c5YNgaTVzrPab2G1p2"; // Замініть на правильний UID адміністратора
        setAdmin(isAdmin);
        setLoggedInUser(user);
        console.log("loggedInUser:", user);
        console.log("isAdmin:", isAdmin);
      } else {
        setAdmin(false);
        setLoggedInUser(null);
        console.log("User is not logged in.");
      }
    });

    return () => unsubscribe();
  }, []);

  const handleAuthChange = (user) => {
    setLoggedInUser(user);
  };

  return (
    <Router>
      <Suspense fallback={<LoadingMessage>Завантаження...</LoadingMessage>}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route
              path="/advantages"
              element={
                <Advantages isAdmin={isAdmin} loggedInUser={loggedInUser} />
              }
            />
            <Route path="/questions" element={<Questions />} />
            <Route path="/contract" element={<Contract />} />
            <Route path="/questionnaire" element={<Questionnaire />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/news" element={<News />} />
          </Route>
          <Route
            path="/auth"
            element={
              <Auth onAuthChange={handleAuthChange} closeModal={() => {}} />
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

const AppWithModalProvider = () => (
  <ModalProvider>
    <App />
  </ModalProvider>
);

export default AppWithModalProvider;
