import axios from "axios";

const BASE_URL = "https://russianwarship.rip/api/v2";

export async function getStatisticsInfo(lang) {
  try {
    const response = await axios.get(`${BASE_URL}/terms/${lang}`);
    const data = response.data.data;
    return data;
  } catch (error) {
    console.error("Помилка запиту до API:", error);
    throw error;
  }
}
