import React from "react";
import { FaTimes } from "react-icons/fa";
import {
  MobilMenuContainer,
  MobilMenuCloseButton,
  MobilMenuList,
  MobilMenuItem,
  MobilMenuLink,
} from "./MobilMenu.styled";

function MobilMenu({ isOpen, onClose }) {
  return (
    <MobilMenuContainer className={isOpen ? '' : 'menu-open'}>
      <MobilMenuCloseButton onClick={onClose}>
        <FaTimes />
      </MobilMenuCloseButton>
      <MobilMenuList>
        <MobilMenuItem>
          <MobilMenuLink to="/" onClick={onClose}>
            Головна
          </MobilMenuLink>
        </MobilMenuItem>
        <MobilMenuItem>
          <MobilMenuLink to="/questions" onClick={onClose}>
            Поширені питання
          </MobilMenuLink>
        </MobilMenuItem>
        <MobilMenuItem>
          <MobilMenuLink to="/advantages" onClick={onClose}>
            Переваги служити за контрактом
          </MobilMenuLink>
        </MobilMenuItem>
        <MobilMenuItem>
          <MobilMenuLink to="/contract" onClick={onClose}>
            Як вступити
          </MobilMenuLink>
        </MobilMenuItem>
        <MobilMenuItem>
          <MobilMenuLink to="/questionnaire" onClick={onClose}>
            Заповнити анкету
          </MobilMenuLink>
        </MobilMenuItem>
        <MobilMenuItem>
          <MobilMenuLink to="/contacts" onClick={onClose}>
            Контакти
          </MobilMenuLink>
        </MobilMenuItem>
        <MobilMenuItem>
          <MobilMenuLink to="/news">Новини</MobilMenuLink>
        </MobilMenuItem>
      </MobilMenuList>
    </MobilMenuContainer>
  );
}

export default MobilMenu;
