import React from 'react';
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import {LanguageContainer, LanguageButton} from './LanguageSelector.styled';

import enTranslation from '../locales/en.json'; 
import uaTranslation from '../locales/ua.json';


i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    ua: { translation: uaTranslation },
  },
  lng: 'ua', 
  interpolation: {
    escapeValue: false, 
  },
});

function LanguageSelector() {
  const { t } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <LanguageContainer>
      <LanguageButton onClick={() => changeLanguage('en')}>{t('EN')}</LanguageButton>
      <LanguageButton onClick={() => changeLanguage('ua')}>{t('UA')}</LanguageButton>
    </LanguageContainer>
  );
}

export default LanguageSelector;
