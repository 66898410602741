import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  AuthContainer,
  AuthButton,
  AuthInput,
  StyledModal,
  CloseButton,
  StyledModalForm,
  StyledModalInputPassword,
  StyledModalInputEmail,
  StyledshowPasswordCheckbox,
} from "./Auth.styled";
import { initializeApp } from "firebase/app";

// Змінені дані конфігурації Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBFDmNTfqFWgi-i83npP5kTlcLeSie0qvk",
  authDomain: "auth-tck.firebaseapp.com",
  projectId: "auth-tck",
  storageBucket: "auth-tck.appspot.com",
  messagingSenderId: "289458895890",
  appId: "1:289458895890:web:c80a6363930fcf31d96ccb",
  measurementId: "G-SKMYC10FWX",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const Auth = ({ onAuthChange, closeModal }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Показ паролю

  const [user] = useAuthState(auth);
  const [isSignIn, setIsSignIn] = useState(true);
  const [error, setError] = useState(null);

  const handleAuth = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setError("Please enter email and password");
      return;
    }

    try {
      if (isSignIn) {
        await signInWithEmailAndPassword(auth, email, password);
      } else {
        await createUserWithEmailAndPassword(auth, email, password);
      }

      setEmail("");
      setPassword("");
      setError(null);

      onAuthChange(user);
      closeModal();
    } catch (error) {
      setError("Authentication failed. Please check your credentials.");
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      onAuthChange(null);
    } catch (error) {
      console.error(error.message);
    }
  };

  const toggleSignIn = () => {
    setIsSignIn((prevIsSignIn) => !prevIsSignIn);
    setError(null);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <StyledModal>
      <AuthContainer>
        {user ? (
          <>
            <p>Logged in as {user.email}</p>
            <AuthButton onClick={handleSignOut}>Sign Out</AuthButton>
            <CloseButton onClick={closeModal}>Close</CloseButton>
          </>
        ) : (
          <StyledModalForm onSubmit={handleAuth}>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <AuthButton onClick={toggleSignIn}>
              {isSignIn ? "Sign In" : "Register"}
            </AuthButton>
            <StyledModalInputEmail>
              <label>Email:</label>
              <AuthInput
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </StyledModalInputEmail>
            <StyledModalInputPassword>
              <label>Password:</label>
              <AuthInput
                type={showPassword ? "text" : "password"} // Показ паролю
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label>Show Password</label>
              <StyledshowPasswordCheckbox type="checkbox" onClick={handleShowPassword} />
            </StyledModalInputPassword>
            <AuthButton type="submit">
              {isSignIn ? "Sign In" : "Register"}
            </AuthButton>
            <CloseButton onClick={closeModal}>Close</CloseButton>
          </StyledModalForm>
        )}
      </AuthContainer>
    </StyledModal>
  );
};

export default Auth;
