import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const MobilMenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #3d4721;
  overflow: hidden;
  z-index: 1000;
  height: 100%;
  opacity: 1;
  transition: opacity 0.3s ease;

  &.menu-enter,
  &.menu-exit-active {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &.menu-enter-active,
  &.menu-exit {
    opacity: 0;
  }
`;

export const MobilMenuCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background-color: transparent;
  color: #fff;
`;

export const MobilMenuList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 0 5px 10px 5px;
  border-bottom: 1px #fff solid;
`;

export const MobilMenuItem = styled.li`
  width: 130px;
`;

export const MobilMenuLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  color: #fff;
  border: #fff solid;
  border-radius: 20px;
  font-size: 12px;
  letter-spacing: -0.8px;
  padding: 5px;
`;
