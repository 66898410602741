import styled from "styled-components";

export const StyledFooter = styled.footer`
  border-top-width: 2px;
  border-top-color: #18181a;
  border-top-style: solid;
  box-shadow: 0 0 8px 0 rgb(42, 42, 44);
  padding: 10px;
  background-color: #3d4721;
  @media only screen and (max-width: 767px) {
   display: none;
  }
`;

export const StyledContactsList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const StyledContactsLinks = styled.a`
  display: flex;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  width: 185px;
  color: #fff;
  border: #fff solid;
  border-radius: 20px;
  padding: 8px;
  transition-property: background-color;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus {
    border: #a0ac82 solid;
    color: #a0ac82;
  }

  @media only screen and (max-width: 1023px) {
    font-size: 12px;
    letter-spacing: -0.8px;
    padding: 5px;
    width: 160px;
  }
`;
