import styled from "styled-components";

export const HomePageTitle = styled.h2`
  text-align: center;
  margin-bottom: 24px;
  text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;
  @media only screen and (max-width: 1023px) {
    font-size: 21px;
  }
`;

export const HomePageNewsContainer = styled.div`
  background-color: #f3f3f3;
  color: #333;
  font-family: "Roboto", sans-serif;
  max-width: 650px;
  border-radius: 5px;
  padding: 10px;
  height: 1043px;
  overflow: auto;
  @media only screen and (max-width: 767px) {
    max-width: 100%;
  }
`;
export const HomePageNewsList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;
export const HomePageNewsItem = styled.li`
  display: flex;
  width: 600px;
  @media only screen and (max-width: 767px) {
    width: 319px;
  }
`;

export const HomePageNewsImg = styled.img`
  margin-right: 10px;
  max-width: 150px;
`;
export const HomePageNewsTitles = styled.h3`
  font-size: 16px;
  letter-spacing: -0.8px;
  color: #333;
  @media only screen and (max-width: 767px) {
    max-width: 120px;
  }
`;

export const HomePageNewsLink = styled.a`
  display: flex;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  color: #333;
  padding: 8px;
`;

export const HomePageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;
  @media only screen and (max-width: 1300px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
`;

export const LoadingMessage = styled.p`
  color: #888;
  font-size: 32px;
  text-align: center;
`;