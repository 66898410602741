import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { SlMenu } from "react-icons/sl";
import MobilMenu from "../MobilMenu/MobilMenu";
import Auth from "../Authorization/Auth";
import {
  HeaderWrapper,
  StyledHeaderLink,
  HeaderTitle,
  HeaderImage,
  HeaderButton,
  HeaderAthuButton,
} from "./Header.styled";
import HeaderImg from "./HeaderImg/ukraine.png";
import HeaderIconImg from "./HeaderImg/header-icon.png";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import { useModal } from "react-modal-hook"; // Додав цей імпорт

function Header() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  const [showAuthModal, hideAuthModal] = useModal(() => (
    // Змінив виклик useModal
    <Auth setAdminStatus={() => {}} closeModal={hideAuthModal} />
  ));

  const toggleMenu = () => {
    setMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    closeMenu();
  }, [location.pathname]);

  return (
    <HeaderWrapper>
      <StyledHeaderLink to="/">
        <img src={HeaderIconImg} alt="Емблема ЗСУ" width="60" />
      </StyledHeaderLink>
      <HeaderTitle>{t("HeaderTitle")}</HeaderTitle>
      <HeaderImage src={HeaderImg} alt="Прапор України" width="60" />
      <LanguageSelector />
      <HeaderButton type="button" onClick={toggleMenu}>
        <SlMenu />
      </HeaderButton>
      {isMenuOpen && <MobilMenu onClose={closeMenu} />}

      <HeaderAthuButton type="button" onClick={showAuthModal}>
        Sign in
      </HeaderAthuButton>

      {isMenuOpen && <MobilMenu onClose={closeMenu} />}
    </HeaderWrapper>
  );
}

export default Header;
