import styled from "styled-components";

export const AuthContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  background-color: #3d4721;
  color: white;
`;

export const AuthButton = styled.button`
  display: flex;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  background-color: transparent;
  color: #a0ac82;
  border: #a0ac82 solid;
  border-radius: 20px;
  padding: 8px;
  transition-property: background-color;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  &:hover,
  &:focus {
    border: #a0ac82 solid;
    color: #a0ac82;
  }
`;

export const AuthInput = styled.input`
  margin: 5px 0;
  padding: 8px;
  font-size: 16px;
`;

export const StyledModal = styled.div`
  width: 600pxs;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgb(204, 204, 204);
  background: #3d4721;
  padding: 20px;
  z-index: 1000;
  border-radius: 4px;
  outline: none;
  max-width: 400px;
  width: 100%;
  color: white;
`;

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  background-color: transparent;
  color: #a0ac82;
  border: #a0ac82 solid;
  border-radius: 20px;
  padding: 8px;
  transition-property: background-color;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  &:hover,
  &:focus {
    border: #a0ac82 solid;
    color: #a0ac82;
  }
`;
export const StyledModalForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  background-color: #3d4721;
  color: white;
`;

export const StyledModalInputEmail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledModalInputPassword = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledshowPasswordCheckbox = styled.input`
  position: absolute;
  top: 210px;
  left: 80px;
`;
